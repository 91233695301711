<ng-template #imageCopyrightData>
  <form [formGroup]="copyrightForm">
    <div id="main-img-container">
      <div id="image-copyright-info" *ngIf="mediaList.length > 0">
        <ng-container *ngFor="let mediaFile of mediaList; let i = index">
          <div class="copyright-block">
            <div class="image-copyright-main-region">
              <div class="image-region">
                <div class="image-title header-section">
                  <span>{{'BP_LM_IMAGE_PREVIEW_LBL' | customTranslation: translationData}}</span>
                </div>
                 <img [src]="mediaFile.media" alt="media"  class="user-media" />
                <!-- <div class="img-size-section">
                  <span>{{changeImgSizeToMb(mediaFile.originalMedia.size)}} Mb</span>
                </div> -->
              </div>
              <div class="copyright-info-region" formGroupName="licenses">
                <div class="close-section-container header-section" role="button">
                  <div class="close-section">
                    <span class="icon icon_close" (click)="removeImgWithCopyrightData(i)"></span>
                  </div>
                </div>
                <div class="form-row form-row-wgs">
                  <div class="form-item material form-clm-6">
                    <input type="text" [formControlName]="'attribution' + i" 
                    [ngClass]="{'not-empty': copyrightForm.get('licenses.attribution' + i)?.value}"
                    />
                    <label>{{
                      LOCATIONS_CONSTANTS_LIST.HELPER_INPUT_LABELS.IMAGE_ATTRIBUTION
                      | customTranslation : translationData
                      | sentencecase
                      }}</label>
                  </div>
                  <div class="form-item material form-clm-6">
                    <input type="text" [formControlName]="'license' + i" 
                    [ngClass]="{'not-empty': copyrightForm.get('licenses.license' + i)?.value}"/>
                    <label>{{
                      LOCATIONS_CONSTANTS_LIST.HELPER_INPUT_LABELS.IMAGE_LICENSE
                      | customTranslation : translationData
                      | sentencecase
                      }}</label>
                  </div>
                </div>
                <div class="form-row form-row-wgs">
                  <div class="form-item material form-clm-6">
                    <input type="text" [formControlName]="'license_url' + i"
                      [ngClass]="{'error': !copyrightForm.get('licenses.license_url' + i)?.valid, 
                                                  'not-empty': copyrightForm.get('licenses.license_url' + i)?.value}" />
                    <label>{{
                      LOCATIONS_CONSTANTS_LIST.HELPER_INPUT_LABELS.LICENCE_URL
                      | customTranslation : translationData
                      | sentencecase
                      }}</label>
                  </div>
                  <div class="form-item material form-clm-6">
                    <input type="text" [formControlName]="'subject_url' + i"
                      [ngClass]="{'error': !copyrightForm.get('licenses.subject_url' + i)?.valid, 
                                                  'not-empty': copyrightForm.get('licenses.subject_url' + i)?.value}" />
                    <label>{{
                      LOCATIONS_CONSTANTS_LIST.HELPER_INPUT_LABELS.IMAGE_URL
                      | customTranslation : translationData
                      | sentencecase
                      }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="img-upload-container upload-img-section">
          <div>
            <span class="icon big upload-image"></span>
          </div>
          <div class="img-upload-text">
            <span>Add more images and details.</span>
          </div>
          <div class="file-upload-region-box">
            <input type="file" id="image-upload" accept=".jpg, .jpeg, .png" 
            (change)="onFileUpload($event)" (click)="bannerfileField.value = ''"
            multiple="multiple" #bannerfileField hidden />
            <label for="image-upload">{{buttonNames.BROWSE | customTranslation: translationData}}</label>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>

<app-common-modal [isCloseModal]="false" [closeButtonTitle]="dialogData.secondaryActionBtnTitle"
  [saveButtonTitle]="dialogData.primaryActionBtnTitle" [isHeaderVisible]="true" [isFooterVisible]="true"
  [showModalContent]="imageCopyrightData" [isFooterVisible]="true" *ngIf="showModal" [type]="dialogData.type"
  (closeModalEmitter)="closeModal()" (saveModalEmitter)="saveImageWithCopyRightData()" 
  [doneButtonDisabled]="((copyrightForm.invalid || !this.mediaList.length) ? 'INVALID': 'VALID')"></app-common-modal>